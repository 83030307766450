import { Trans } from 'react-i18next';
import { CompanyPrivacyLink } from '@entities/company';
import Typography, { getTypographyClass } from '@ui/Typography';
import { memo } from 'react';

const FormFooter = () => (
  <Typography variant="caption1" className="tracking-[-.015em] mt-3">
    <Trans
      i18nKey="contacts.footer"
      components={{
        privacy: (
          <CompanyPrivacyLink
            className={getTypographyClass({
              variant: 'link2',
              className: 'text-primary400',
            })}
          />
        ),
      }}
    />
  </Typography>
);

export default memo(FormFooter);
