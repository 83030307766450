import { memo } from 'react';
import clsx from 'clsx';

const InfoColumn = ({ children, className }) => (
  <div className={clsx('flex flex-col space-y-[6px]', className)}>
    {children}
  </div>
);

export default memo(InfoColumn);
