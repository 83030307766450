import { memo } from 'react';
import StaticIcon from '@ui/StaticIcon';
import Image from '@ui/Image';
import Typography from '@ui/Typography';
import {
  FeedbackMailLink, FeedbackPhoneLink, useContactsQuery,
} from '@entities/company';
import { t } from '@lib/i18n';
import clsx from 'clsx';
import MediaQuery from '@ui/MediaQuery';
import { InfoColumn } from '.';

export const ManTelegramLink = ({ className, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      className={clsx(
        className,
        '!m-0 flex items-center',
      )}
    >
      <StaticIcon name="TG-Monochrome" />
    </a>
  );
};

export const ManWhatsAppLink = ({ className, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      className={clsx(
        className,
        '!m-0 flex items-center',
      )}
    >
      <StaticIcon name="WA-Monochrome" />
    </a>
  );
};

const OnlineBlock = () => {
  return (
    <div className="flex h-[29px] w-[221px] flex-row items-center justify-center rounded-[16px] border border-solid border-tertiary400 bg-tertiary200 py-[5px] pl-[10px] pr-[14px]">
      <StaticIcon name="online-status" className="mr-[5px]" />
      <Typography className="text-xs">
        отвечу в течение 4-х часов
      </Typography>
    </div>
  );
};

const SalesManCard = ({
  person: {
    avatar, name, role, alt_image, tgLink, waLink,
  },
  className = '',
  variant,
}) => {
  const { address } = useContactsQuery();

  return (
    <div className={clsx(className, 'sm:bg-white md-down:rounded-lg md-down:border md-down:pt-[15px]')}>
      <div className="flex flex-col sm:text-center">
        <div className="md-up:flex md-up:space-x-[22px]">
          <Image src={avatar} alt={alt_image} className="w-[64px] md-down:m-auto" />
          <InfoColumn className="justify-center sm:mt-[18px] lg:mt-[3px] lg-down:space-y-1 xl:mt-[5px]">
            <div className="flex flex-col md-down:justify-center">
              <div className="flex items-center md-up:mb-[6px] md-down:justify-center lg-down:mb-[8px]">
                <Typography variant="header4" className="font-medium lg:tracking-[-0.22px]">
                  {name}
                </Typography>
                <div>
                  <MediaQuery greaterThanOrEqual="md">
                    <div className="ml-[8px]">
                      {
                        variant === 'support' && (
                          <OnlineBlock />
                        )
                      } 
                    </div>
                  </MediaQuery>
                </div>
              </div>
              <Typography>
                {role}
              </Typography>
            </div>
          </InfoColumn>
        </div>
        <div className="sm:mt-4 sm:space-y-4 md-up:mt-[34px] md-up:flex md-up:space-x-4">
          <InfoColumn>
            <Typography variant="caption2" className="uppercase" color="secondary100">
              {t('common.phone')}
            </Typography>
            <FeedbackPhoneLink variant={variant} />
          </InfoColumn>
          <InfoColumn className="w-[222px] md-down:m-auto">
            <Typography variant="caption2" className="uppercase" color="secondary100">
              {t('common.email')}
            </Typography>
            <div className="flex md-down:justify-center">
              <FeedbackMailLink variant={variant} className="flex items-center" />
            </div>
          </InfoColumn>
          <InfoColumn className="!flex-row items-end gap-[16px] md-down:justify-center">
            <ManTelegramLink link={tgLink} />
            <ManWhatsAppLink link={waLink} />
          </InfoColumn>
        </div>
        <div className="m-auto sm:pt-[24px] md:pt-[24px] md-up:pt-[16px]">
          <MediaQuery lessThan="md">
            <div>
              {
                variant === 'support' && (
                  <OnlineBlock />
                )
              } 
            </div>
          </MediaQuery>
        </div>
        {
          variant !== 'support' && (
            <div className="flex w-full md-up:my-[20px] md-up:hidden">
              <div className="w-1/12" />
              <div className="h-[1px] w-10/12 bg-tertiary300" />
              <div className="w-1/12" />
            </div>
          )
        }
        <div className="flex w-full md-up:mb-[16px] md-down:hidden">
          <div className="h-[1px] w-full bg-tertiary300" />
        </div>
        <div className="sm:px-[16px]">
          <Typography className="mb-[20px] whitespace-pre-line sm:mt-[20px]" variant="body2">
            { variant === 'support' 
              ? 'Настройки, ошибки, баги? Всё решим.\nВыбирайте любой удобный способ связи. С\u00a09:00\u00a0до\u00a018:00\u00a0МСК в рабочие дни.'
              : 'Как это работает, сколько стоит, как попробовать? Отвечу на все вопросы.' }
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default memo(SalesManCard);
